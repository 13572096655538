<template>
  <div>
    <div class="d-flex justify-content-between">
      <h4>Места</h4>
      <b-button variant="outline-primary" @click="addClicked">Добавить</b-button>
    </div>
    <b-table
      class="mt-3"
      :fields="fields"
      :items="list"
      @row-clicked="(row) => rowClicked(row)"
      tbody-tr-class="pointer"
    >
      <template #cell(type)="{ value, item }">
        <div class="d-flex justify-content-between align-items-center flex-nowrap">
          <span>{{ translate(value) }}</span>
          <div class="text-nowrap">
            <b-icon
              icon="pencil"
              variant="primary"
              class="mr-3"
              @click="rowClicked(item, $event)"
            />
            <b-icon icon="trash-fill" variant="primary" @click="deleteClicked(item, $event)" />
          </div>
        </div>
      </template>
    </b-table>
    <div class="d-flex justify-content-center">
      <b-pagination class="m-auto" v-model="currentPage" :total-rows="total" per-page="25" />
    </div>
  </div>
</template>

<script>
import Vue from 'vue';

import { PlaceTypes } from '@/request';

const TYPES_RU = {
  rest: 'Отдых',
  travel: 'Посетить',
};

export default Vue.extend({
  name: 'PlaceTypes',
  data() {
    return {
      list: [],
      fields: [
        { key: 'title', label: 'Название' },
        { key: 'title_plural', label: 'Название (м.ч.)' },
        { key: 'type', label: 'Тип' },
      ],
      currentPage: 1,
      total: 0,
    };
  },
  mounted() {
    this.loadPlaceTypes(this.currentPage);
  },
  methods: {
    translate(value) {
      return TYPES_RU[value];
    },
    async loadPlaceTypes(page) {
      try {
        const { data, total } = await PlaceTypes.getPlaceTypes({ page });
        this.list = data;
        this.total = total;
      } catch (e) {
        this.$bvToast.toast(e.message, { variant: 'danger' });
      }
    },
    addClicked() {
      this.$router.push({ name: 'NewPlaceType' });
    },
    rowClicked(row, event = undefined) {
      event?.stopPropagation();

      this.$router.push({ name: 'EditPlaceType', params: { id: row.id } });
    },
    async deleteClicked(row, event) {
      event.stopPropagation();

      const userAgreed = await this.$bvModal.msgBoxConfirm(
        'Вы действительно хотите удалить тип места',
        {
          okTitle: 'Удалить',
          cancelTitle: 'Отмена',
          cancelVariant: 'outline-secondary',
        },
      );

      if (!userAgreed) {
        return;
      }

      try {
        await PlaceTypes.deletePlaceType(row.id);
        this.$bvToast.toast('Тип места удален', { variant: 'success' });
        await this.loadPlaceTypes();
      } catch (e) {
        this.$bvToast.toast(e.message, { variant: 'danger' });
      }
    },
  },
  watch: {
    currentPage(newVal) {
      this.loadPlaceTypes(newVal);
    },
  },
});
</script>

<style lang="scss" scoped></style>
